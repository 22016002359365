import * as Sentry from '@sentry/remix';
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { startTransition, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  tracesSampleRate: 1,
  enabled: import.meta.env.PROD,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    // eslint-disable-next-line import/namespace
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  //   While you're testing, we recommend that you set replaysSessionSampleRate to 1.0. This ensures that every user session will be sent to Sentry.
  // Once testing is complete, we recommend lowering this value in production. We still recommend keeping replaysOnErrorSampleRate set to 1.0.
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
});

startTransition(() => {
  hydrateRoot(document, <RemixBrowser />);
});
